.Tabs {
  button {
    background: transparent;
    border: none;
    font-weight: bold;
    color: $black;
    padding: 8px 0;
    cursor: pointer;

    &.active {
      box-shadow: 0px 2px 0px $blue;
    }

    outline: 0;
  }
}