.Assets {
  $container-height: 300px;
  $condensed-container-height: 200px;

  width: 400px;
  color: $black;
  text-shadow: none;
  position: absolute;
  top: 10px;
  left: 100%;
  margin-left: 10px;
  transform: translateY(-50%);
  z-index: 100;

  &.open-right {
    left: 100%;
  }

  &.open-left {
    left: auto;
    right: 60px;
  }

  &.arrow-box-left {
    position: absolute; // has to be the same as the main class (.Assets in this case)
    background: #ffffff;
    border: 1px solid $gray-300;

    &:after, &:before {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-right-color: #ffffff;
      border-width: 15px;
      margin-top: -15px;
    }
    &:before {
      border-color: rgba(130, 130, 130, 0);
      border-right-color: $gray-500;
      border-width: 16px;
      margin-top: -16px;
    }
  }

  &.arrow-box-right {
    @extend .arrow-box-left;

    &:after, &:before {
      left: 100%;
    }
    &:after {
      border-right-color: transparent;
      border-left-color: #ffffff;
    }
    &:before {
      border-right-color: transparent;
      border-left-color: $gray-500;
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    cursor: pointer;
  }

  .Tabs {
    border-bottom: 1px solid $gray-300;
  }

  .search-bar {
    margin: 10px 0;
    & > div {
      background: $gray-100;
      font-size: 14px;

      input {
        border-color: $gray-300;
        padding: 10px 5px;
      }

      .fa {
        color: $gray-500;

      }
    }
  }

  .results-container {
    position: relative;
    height: $container-height;

    .results-wrapper {
      height: $container-height;
      overflow: auto;
    }
    .results-shadow {
      position: absolute;
      height: 1px;
      width: 100%;
      box-shadow: 0px 0px 30px 20px #fff;
      z-index: 1;
      top: $container-height;
    }
  }


  &.condensed {
    .results-container {
      height: $condensed-container-height;

      .results-wrapper {
        height: $condensed-container-height;
      }
      .results-shadow {
        top: $condensed-container-height;
      }
    }
  }

  button.add-to-project {
    background: $blue;
    color: $white;
    border-radius: 50px;
    transition: none;
    border: 1px solid darken($blue, 10%);
    padding: 10px 30px;
  }
}