.UserLayout {
  .content {
    padding-left: 110px;
    padding-top: 0px;
  }

  .OwnerDashboard,
  .ContractorDashboard,
  .GcDashboard,
  .ArchitectDashboard,
  .AdminDashboard {
    .card {
      .card-body {
        height: 250px;
        overflow: auto;
      }
    }
  }

  .page-status {
    position: fixed;
    top: 10px;
    left: 50%;
    margin-left: -40px;
    background: #efa02e;
    border: 1px solid #d98711;
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    text-align: center;
    border-radius: 2px;
    z-index: 100;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.09);
    text-shadow: 1px 1px 0px #d98711;
    text-transform: uppercase;

    label {
      color: #fff;
    }
  }
}