html, body {
  .Register,
  .Login {
    background: url('../../assets/img/bg-login.jpg') 0 0 no-repeat #000;
    background-size: 100%;
    margin-left: 105px;

    @include mobile() {
      margin-left: 30px;
    }

    .bottom-left {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: auto;
      margin-bottom: 0px;
    }
  }
}
