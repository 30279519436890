.PublicLayout {

  .content {
    padding-left: 110px;
  }

  .main-panel {
    margin-bottom: 70px;
  }

  &.no-footer {
    .main-panel {
      margin-bottom: 0px;
    }
  }
}