.AdminLayout {
  .content {
    padding-left: 110px;
    padding-top: 0px;
  }

  .AdminDashboard {
    .card {
      .card-body {
        height: 260px;
        overflow: auto;
      }
    }
  }

}