// Global styles

.border-gray {
  border-color: #c4c4c4 !important;
}

.text-muted {
  color: $gray-500 !important;
}

.fab-add-btn-container {
  position: relative;

  .fab-add-btn {
    position: absolute;
    top: -5px;
    right: 10px;
    z-index: 10;
    width: 30px;
    height: 30px;
    min-height: auto;
    font-size: 10px;
  }
}

.custom-fieldset {
  position: relative;
  margin-top: -10px;
  padding: 10px;
  border: 1px solid $gray-400;
  z-index: auto;
  min-height: 62px;

  & > legend {
    font-size: 12px;
    display: inline-block;
    width: auto;
    padding: 0 5px;
    margin: 0;
  }
}

.bg-lighter {
  background: $gray-100;
}

.bg-light-gray {
  background: $gray-200;
}

.font-weight-600 {
  font-weight: 600;
}