.UserProfile {
  .field-email {
    position: relative;

    .change-password-link {
      display: none;
    }

    &:hover {
      .change-password-link {
        display: block;
      }
    }
  }
}