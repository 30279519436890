#footer {
  margin-left: 110px;
  width: calc(100% - 110px);

  background: #000;
  color: $yellow;

  .nav {
    padding-left: 0;

    .nav-item {
      padding: 0 10px;
      border-right: 1px solid $yellow;
      line-height: 11px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  a {
    color: $yellow;
    font-weight: bold;
    font-size: 14px;
  }

  .copyright {
    color: $yellow;
  }
}