@font-face {
    font-family: 'puente-bold';
    src: url('../fonts/puente_bold.ttf') format('truetype');
}

@font-face {
    font-family: 'puente-light';
    src: url('../fonts/puente_light.ttf') format('truetype');
}
.puente-light {
    font-family: 'puente-light', sans-serif;
}
.puente-bold {
    font-family: 'puente-bold', sans-serif;
}

