@import "mixins/alert.scss";
@import "mixins/badges.scss";
@import "mixins/background-variant.scss";
@import "mixins/buttons.scss";
@import "mixins/forms.scss";
@import "mixins/icon.scss";
@import "mixins/modals.scss";
@import "mixins/popovers.scss";
@import "mixins/page-header.scss";
@import "mixins/vendor-prefixes.scss";
@import "mixins/opacity.scss";
@import "mixins/modals.scss";
@import "mixins/inputs.scss";
@import "mixins/dropdown.scss";
@import "mixins/wizard.scss";

/*

 Define the breakpoints for tablets and desktop.
 Example usage:

 div {
  @include tablet {
    background: red;
  }

  @include desktop {
    background: green;
  }
 }

 */
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  /*@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {*/

  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}