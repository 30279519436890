.SideBanner {
  width: 100px;
  height: calc(100vh - 110px);
  float: left;
  background: $yellow;
  position: fixed;
  z-index: 100;

  .house-icon-wrapper {
    background: $gray-200;
    padding-bottom: 10px;

    .house-icon {
      background: #000000;
      padding: 10px 20px 15px;
      cursor: pointer;
    }
  }

  .yellow-row {
    height: 100%;

    & > div {
      background: $yellow;
    }
    .banner-wrapper {
      height: 100%;

      img {
        max-width: 100%;
        margin-bottom: 20px;
      }

      @include mobile {
        height: auto;
      }
    }

    .link-wrapper {
      white-space: nowrap;
      text-align: center;

      a {
        color: $black;
        font-size: 10px;
        font-weight: bold;
      }
    }

    .support-link {
      background: #000000;
      color: $yellow;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      padding: 7px 15px;
      text-align: center;
      margin: 0 auto;
      left: -6px;
      position: relative;
    }
  }
}