.UserHeader {
  padding: 0px 15px 0px 110px;
  position: relative;
  z-index: 10;

  .card {
    min-height: 80px;

    h1 {
      font-size: 58px;
    }

    .navbar {
      .photo {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;

        img {
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
          border-radius: 0px;
        }
      }

      .dropdown-toggle {
        &.messages-toggle {
          margin: 10px 20px 0 0;
          position: relative;

          .notification {
            top: 4px;
            right: 8px;
            width: 8px;
            height: 8px;
          }
        }

        &.user-profile {
          padding: 4px;
          border: 1px solid $gray-400;

          .photo {
            width: 60px;
            height: 60px;
          }
        }
      }

      .user-fullname {
        h3 {
          line-height: 24px;
        }
      }
    }
  }
}